<template>
  <div>
    <template>
      <v-snackbar :timeout="5000" :value="true" top :color="isError ? 'error' : 'success'" center v-model="snackBar"
        outlined elevation="1" text>
        {{ snackBarText }}
      </v-snackbar>
    </template>
    <div v-if="isloading">
      <spinner></spinner>
    </div>
    <div class="text-right">
      <v-btn color="primary" dark class="mb-5 text-right" @click="addTax" data-cy="addBtn">
        ADD NEW
      </v-btn>
    </div>
    <v-data-table :headers="headers" :items="taxes" class="elevation-1" hide-default-footer>
      <template v-slot:top>
        <v-dialog v-model="dialog" max-width="500px" persistent>
          <v-card>
            <v-card-title class="text-h5 py-5  justify-center">
              <span>{{ formTitle }}</span>
            </v-card-title>
            <v-form ref="formTax" @submit.prevent="save" v-model="valid">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-select :items="taxLabels" dense class="text-capitalize" outlined v-model="taxForm.label" data-cy="label-select"
                        :rules="[required('Label')]"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field dense outlined v-model="taxForm.amount" label="Chargeable Income" data-cy="amount-input"
                        :rules="[selectionRequired('Amount')]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field dense outlined v-model="taxForm.percentage" label="Rate (%)" data-cy="percentage-input"
                        :rules="[selectionRequired('Percentage')]"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary darken-1" @click="close" data-cy="cancelBtn">
                  Cancel
                </v-btn>
                <v-btn color="primary darken-1" type="submit" data-cy="title-btn">
                  {{ btnTitle }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-text class="text-h6">Are you sure you want to delete this item?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary darken-1" @click="closeDelete" data-cy="cancelBtn">Cancel</v-btn>
              <v-btn color="primary darken-1" @click="deleteItemConfirm" data-cy="submitBtn">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon data-cy="tax-edit" color="primary" size="24" dense class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon color="primary" size="24" dense @click="deleteItem(item)" data-cy="tax-delete">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        NO DATA AVAILABLE
      </template>
    </v-data-table>
  </div>
</template>

<script>
import validation from "../services/validation";
import { LIST_TAXES } from '../services/graphql/queries/allQueries';

export default {
  data: () => ({
    ...validation,
    dialog: false,
    valid: false,
    editMode: false,
    dialogDelete: false,
    isError: true,
    snackBar: false,
    snackBarText: "",
    isloading: false,
    headers: [
      {
        text: "LABEL",
        sortable: false,
        value: "label",
      },
      { text: "CHARGEABLE INCOME", value: "amount", sortable: false },
      { text: "RATE(%)", value: "percentage", sortable: false },
      { text: "ACTIONS", value: "actions", align: "end", sortable: false },
    ],
    taxes: [],
    taxLabels: [
      "level 1",
      "level 2",
      "level 3",
      "level 4",
      "level 5",
      "level 6",
      "level 7",
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {
      amount: null,
      percentage: null,
      label: "",
    },
    taxForm: new Form({
      id: null,
      amount: null,
      percentage: null,
      label: "",
    }),

  }),
  computed: {
    formTitle() {
      return !this.editMode
        ? "Add Statutory Deductions"
        : "Edit Statutory Deductions";
    },
    btnTitle() {
      return !this.editMode ? "Add" : "Update";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    fetchTaxes() {
      this.isloading = true;
      return new Promise((resolve, reject) => {
        this.$apollo.query({
          query: LIST_TAXES,
          fetchPolicy: 'network-only',
        })
          .then((res) => {
            this.isloading = false;
            this.taxes = res.data.listPayeConfigs.data.map((paye) => ({
              id: paye.id,
              label: paye.label,
              percentage: paye.percentage,
              amount: paye.amount
            }))
            resolve(res.data.listPayeConfigs)
          })
          .catch((err) => {
            this.isloading = false;
            reject(err)
          })
      })


    },
    initialize() {
      return this.fetchTaxes();
    },
    editItem(item) {
      this.editMode = true
      this.taxForm.fill(item)
      this.dialog = true;

    },
    addTax() {
      this.editMode = false
      this.dialog = true;
      setTimeout(() => {
        this.$refs.formTax.reset()
        this.taxForm.reset()
      }, 100)
    },
    deleteItem(item) {
      this.editedIndex = this.taxes.indexOf(item);
      this.editedItem = { ...item };

      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.isloading = true
      this.closeDelete();
      this.taxes.splice(this.editedIndex, 1);
      await this.$store.dispatch("statutory/deleteTax", this.editedItem);
      await this.initialize();
      this.isError = false;
      this.snackBar = true;
      this.snackBarText = " Tax Deleted Successfully";
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    async save() {
      if (!this.$refs.formTax.validate()) return
      let record = {
        id: this.taxForm.id,
        amount: parseFloat(this.taxForm.amount),
        percentage: parseFloat(this.taxForm.percentage),
        label: this.taxForm.label,
      }
      if (this.editMode) {
        this.isloading = true;
        this.close();
        this.$store.dispatch("statutory/editTax", record)
          .then(async () => {
            this.isError = false;
            await this.initialize();
            this.snackBar = true;
            this.snackBarText = "Tax Updated Successfully";
          })
          .catch(() => {
            this.isError = true;
            this.snackBar = true;
            this.snackBarText = " Error Occurred While Updating Tax";
          })
      }
      else {
        const data = []
        data.push(record)
        this.isloading = true;
        this.close();
        this.$store.dispatch("statutory/createTax", data)
          .then(async () => {
            this.isError = false;
            await this.initialize();
            this.snackBar = true;
            this.snackBarText = " Tax Created Successfully";
          })
          .catch(() => {
            this.isError = true;
            this.snackBar = true;
            this.snackBarText = " Error Occurred While Saving Tax";
          })
      }
    },
  },
};
</script>
<style scoped>
.col-12,
.col.sm-12,
.col-md-12 {
  padding: 0 10px !important;
}
</style>
